export const QUERY_CONSTANTS = {
  PORTFOLIO_HANDLE: 'Portfolio',
  PORTFOLIO_META_NAMESPACE: 'portfolio',
  PORTFOLIO_META_KEY_LINK: 'link',
}

export const STOREFRONT_QUERY = {
  LAYOUT_QUERY: `#graphql
    query layoutMenus(
      $language: LanguageCode
      $headerMenuHandle: String!
      $footerMenuHandle: String!
    ) @inContext(language: $language) {
      shop {
        id
        name
        description
      }
      headerMenu: menu(handle: $headerMenuHandle) {
        id
        items {
          ...MenuItem
          items {
            ...MenuItem
          }
        }
      }
      footerMenu: menu(handle: $footerMenuHandle) {
        id
        items {
          ...MenuItem
          items {
            ...MenuItem
          }
        }
      }
    }
    fragment MenuItem on MenuItem {
      id
      resourceId
      tags
      title
      type
      url
    }
  `,
  POLICIES_QUERY: `#graphql
    fragment Policy on ShopPolicy {
      id
      title
      handle
    }

    query PoliciesQuery {
      shop {
        privacyPolicy {
          ...Policy
        }
        shippingPolicy {
          ...Policy
        }
        termsOfService {
          ...Policy
        }
        refundPolicy {
          ...Policy
        }
        subscriptionPolicy {
          id
          title
          handle
        }
      }
    }
  `,
  PORTFOLIOS_QUERY: `#graphql
    query Blog(
      $language: LanguageCode
      $blogHandle: String!
      $pageBy: Int!
      $cursor: String
      $namespace: String!
      $key: String!
    ) @inContext(language: $language) {
      blog(handle: $blogHandle) {
        title
        seo {
          title
          description
        }
        articles(first: $pageBy, after: $cursor) {
          edges {
            node {
              metafield(namespace: $namespace, key: $key) {
                value
                type
              }
              author: authorV2 {
                name
              }
              excerpt
              contentHtml
              handle
              id
              tags
              image {
                id
                altText
                url
                width
                height
              }
              publishedAt
              title
            }
          }
        }
      }
    }
    `,
    PORTFOLIO_ARTICLE_QUERY: `#graphql
    query ArticleDetails(
      $language: LanguageCode
      $blogHandle: String!
      $articleHandle: String!
    ) @inContext(language: $language) {
      blog(handle: $blogHandle) {
        articleByHandle(handle: $articleHandle) {
          title
          contentHtml
          publishedAt
          author: authorV2 {
            name
          }
          image {
            id
            altText
            url
            width
            height
          }
          seo {
            description
            title
          }
        }
      }
    }
  `,
}